@tailwind base;
@tailwind components;
@font-face {
	font-family: "NAERIS-Outline";
	src: local('NAERIS') url('/public/fonts/NAERIS-Outline.woff2') format('woff2'),
	url('/public/fonts/NAERIS-Outline.woff') format('woff');
	font-style: normal;
}
@font-face {
	font-family: "NAERIS";
	src: local('NAERIS') url('/public/fonts/Naeris-Regular.woff2') format('woff2'),
	url('/public/fonts/Naeris-Regular.woff') format('woff');
	font-style: normal;
}
@tailwind utilities;
